import PopoverMessageWrapper from '@/common/components/PopoverMessageWrapper';
import { formatDateToHHmm, formatDateToMDHHmm } from '@/utils/date/date';
import useTranslation from '@/utils/i18n/useTranslation';
import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { FaLink, FaPen, FaReply, FaTrash } from 'react-icons/fa';
import { MdMoreHoriz } from 'react-icons/md';

type DisplayCommentHeaderType = {
  id: number;
  userName: string;
  createdAt: Date;
  isCurrentUser: boolean;
  isEdited: boolean;
  showMenu: boolean;
  hideAvatar?: boolean;
  onReply?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onLinkGetClick?: () => void;
  setAvatarBgColor?: (color: string) => void;
  showFullDate?: boolean;
  isEditDisabled?: boolean;
};

const DisplayCommentHeader = (props: DisplayCommentHeaderType) => {
  const {
    userName,
    createdAt,
    isCurrentUser,
    isEdited,
    showMenu,
    hideAvatar,
    onLinkGetClick,
    onDelete,
    onReply,
    onEdit,
    setAvatarBgColor,
    showFullDate,
    isEditDisabled = false,
  } = props;

  const { t } = useTranslation();
  const avatarRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (setAvatarBgColor && avatarRef.current) {
      const computedStyle = window.getComputedStyle(avatarRef.current);
      setAvatarBgColor(computedStyle.backgroundColor);
    }
  }, [setAvatarBgColor]);

  return (
    <Flex alignItems='center'>
      {!hideAvatar && <Avatar name={userName} ref={avatarRef} size='sm' mr='2' fontWeight='bold' />}
      <Text
        color='gray.800'
        fontWeight='bold'
        fontSize='md'
        mr='2'
        noOfLines={1}
        maxWidth={{ base: '100px', md: '300px' }}
      >
        {userName}
      </Text>
      <Text color='gray.500' fontSize='sm'>
        {showFullDate ? formatDateToMDHHmm(createdAt) : formatDateToHHmm(createdAt)}
      </Text>
      {isEdited ? (
        <Text color='gray.500' fontSize='sm' ml='2'>
          {t('edited')}
        </Text>
      ) : (
        <></>
      )}
      <Spacer />
      {showMenu ? (
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label='Options'
              icon={<MdMoreHoriz />}
              color='gray.500'
              bgColor='transparent'
            ></MenuButton>
            <MenuList minWidth='150px' zIndex={10}>
              <MenuItem icon={<FaReply />} onClick={() => (onReply ? onReply() : {})}>
                {t('actions.reply')}
              </MenuItem>
              {isCurrentUser && (
                <PopoverMessageWrapper
                  message={t('warning.no-permission.edit')}
                  isDisabled={isEditDisabled}
                >
                  <MenuItem
                    isDisabled={isEditDisabled}
                    icon={<FaPen />}
                    onClick={onEdit ? () => onEdit() : undefined}
                  >
                    {t('actions.edit')}
                  </MenuItem>
                </PopoverMessageWrapper>
              )}
              {isCurrentUser && (
                <PopoverMessageWrapper
                  message={t('warning.no-permission.delete')}
                  isDisabled={isEditDisabled}
                >
                  <MenuItem
                    isDisabled={isEditDisabled}
                    icon={<FaTrash />}
                    onClick={onDelete ? () => onDelete() : undefined}
                  >
                    {t('actions.delete')}
                  </MenuItem>
                </PopoverMessageWrapper>
              )}
              <MenuItem
                onClick={onLinkGetClick ? () => onLinkGetClick() : undefined}
                fontSize='sm'
                fontWeight='normal'
                icon={<FaLink />}
              >
                {t('link')}
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default DisplayCommentHeader;
