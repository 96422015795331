import { IDisplayUser } from '@/modules/users';
import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Button, Flex } from '@chakra-ui/react';
import DisplayCommentBody from './DisplayCommentBody';
import DisplayCommentHeader from './DisplayCommentHeader';

type DisplayReplyCommentType = {
  id: number;
  user: IDisplayUser;
  createdAt: Date;
  isEdited: boolean;
  comment: string;
  commentLexical?: string;
  onGoToCommentClick: (commentId: number) => void;
};

const DisplayReplyComment = (props: DisplayReplyCommentType) => {
  const { id, user, createdAt, isEdited, comment, commentLexical, onGoToCommentClick } = props;
  const { t } = useTranslation();

  return (
    <Box
      ml='10'
      mr='10'
      mb='2'
      borderLeft='4px'
      borderLeftColor='gray.400'
      padding='2'
      backgroundColor='gray.100'
    >
      <DisplayCommentHeader
        id={id}
        userName={user.name}
        createdAt={createdAt}
        isCurrentUser={false}
        isEdited={isEdited}
        showMenu={false}
        hideAvatar={true}
        showFullDate={true}
      />
      <DisplayCommentBody
        comment={comment}
        commentLexical={commentLexical}
        ml='1'
        onGoToCommentClick={onGoToCommentClick}
      />
      <Flex paddingTop='2'>
        <Button size='xs' color='primary.800' onClick={() => onGoToCommentClick(id)}>
          {t('actions.view-message')}
        </Button>
      </Flex>
    </Box>
  );
};

export default DisplayReplyComment;
