import { Box, IconButton } from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { SwiperSlide } from 'swiper/react';
import DisplayFileItem from '../../DisplayFileItem';
import { LocalAttachmentType, SLIDE_SIZE, SLIDE_STYLE } from '../../RichMessageEditor';
import Swiper, { SCROLLBAR_MODULE } from '../../Swiper';

type DisplayAttachmentsProps = {
  localAttachments: LocalAttachmentType;
  removeFile: (index: number) => void;
};

const DisplayAttachments = ({ localAttachments, removeFile }: DisplayAttachmentsProps) => {
  return (
    <Box paddingTop={localAttachments.length > 0 ? 2 : 0}>
      <Swiper slideSize={SLIDE_SIZE} modules={[SCROLLBAR_MODULE]}>
        {localAttachments.map((attachment, index) => (
          <SwiperSlide key={attachment.fileId} style={SLIDE_STYLE}>
            <Box
              borderRadius='md'
              border='1px'
              position='relative'
              borderColor='gray.300'
              overflow='hidden'
              boxSize='full'
            >
              <DisplayFileItem
                fileId={attachment.fileId}
                name={attachment.name}
                contentType={attachment.contentType}
                path={attachment.path}
                src={attachment.src}
                size={SLIDE_SIZE}
              />
              <Box position='absolute' right='5px' top='5px'>
                <IconButton
                  size='xs'
                  onClick={() => removeFile(index)}
                  icon={<MdClose />}
                  aria-label='remove icon'
                />
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};
export default DisplayAttachments;
