import useTranslation from '@/utils/i18n/useTranslation';
import { Box, Button, Flex, IconButton } from '@chakra-ui/react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { $getRoot, CLEAR_EDITOR_COMMAND, CLEAR_HISTORY_COMMAND } from 'lexical';
import { forwardRef, useImperativeHandle } from 'react';
import { MdSend } from 'react-icons/md';
import { RichTextEditorMode } from '../utils/types';

type EditorSubmitPluginProps = {
  onSubmit: (
    text: string,
    mentionIds: string[],
    jsonText: string,
    plainText: string
  ) => Promise<void>;
  onCancel?: () => void;
  mode: RichTextEditorMode;
  hideSendButton?: boolean;
  isLoading: boolean;
};

export type EditorSubmitPluginHandle = {
  send: () => void;
};

const EditorSubmitPlugin = forwardRef<EditorSubmitPluginHandle, EditorSubmitPluginProps>(
  (props: EditorSubmitPluginProps, ref) => {
    const { onSubmit, mode, onCancel, hideSendButton, isLoading } = props;
    const [editor] = useLexicalComposerContext();
    const { t } = useTranslation();
    const isEmpty = useLexicalIsTextContentEmpty(editor);

    useImperativeHandle(ref, () => ({
      send: onSendClick,
    }));

    const onSendClick = () => {
      editor.getEditorState().read(async () => {
        const root = $getRoot();

        const content = JSON.stringify(editor.getEditorState().toJSON());
        const plainText = root.getTextContent();

        // stringifyからparseすることによって、型を消している
        const contentObject = JSON.parse(content);
        const userIds: string[] = [];
        contentObject['root']['children'].forEach((node: { children: { userId?: string }[] }) =>
          node.children.forEach((node) => {
            if (node.userId) userIds.push(node.userId);
          })
        );

        const html = isEmpty ? '' : $generateHtmlFromNodes(editor);
        await onSubmit(html, [...new Set(userIds)], content, plainText);
        // editの時は再編集時にも同じ値を表示していたいので、clearしない
        if (mode === 'edit') return;
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      });
    };

    const onCancelClick = () => {
      editor.dispatchCommand(CLEAR_HISTORY_COMMAND, undefined);
      onCancel?.();
    };

    if (hideSendButton) return null;

    if (mode === 'add') {
      return (
        <Box ml='4'>
          <IconButton
            aria-label='Send'
            icon={<MdSend />}
            size='md'
            onClick={onSendClick}
            isLoading={isLoading}
          />
        </Box>
      );
    } else {
      return (
        <Flex flex='1' justifyContent='end' mt='2'>
          <Button mr='2' onClick={onCancelClick}>
            {t('actions.cancel')}
          </Button>
          <Button colorScheme='primary' onClick={onSendClick} isLoading={isLoading}>
            {t('actions.save')}
          </Button>
        </Flex>
      );
    }
  }
);
export default EditorSubmitPlugin;
EditorSubmitPlugin.displayName = 'EditorSubmitPlugin';
