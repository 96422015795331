import { IFile } from '@/utils/file/type';
import { useFiles } from '@/utils/file/useFiles';
import { ChakraProps, Flex } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection } from 'lexical';
import { FC } from 'react';
import { GoMention } from 'react-icons/go';
import { MdTextFormat } from 'react-icons/md';
import MediaAttachmentToolbarButton from '../components/MediaAttachmentToolbarButton';
import ToolbarButton from '../components/ToolbarButton';

type BottomToolbarProps = {
  flex?: ChakraProps['flex'];
  onFilesAdded: (files: IFile[]) => void;
  onToggleFormatClick: () => void;
  isActiveToggleFormat: boolean;
  isMentionDisabled?: boolean;
};

const BottomToolbarPlugin: FC<BottomToolbarProps> = (props: BottomToolbarProps) => {
  const {
    flex,
    onFilesAdded,
    onToggleFormatClick,
    isActiveToggleFormat,
    isMentionDisabled = false,
  } = props;
  const { getFileUploadUrls } = useFiles();

  const [editor] = useLexicalComposerContext();

  const onMentionClick = () => {
    editor.focus(mentionCallBack);
  };

  const mentionCallBack = () => {
    editor.update(() => {
      const selection = $getSelection();
      const selectionText = selection?.getTextContent();

      if (!selection) {
        return;
      }

      const node = selection.getNodes()[0];
      if (node) {
        const nodeText = node.getTextContent();
        if (nodeText !== selectionText) {
          selection.insertText(' ');
        }
      }

      const textToInsert = selectionText ? `@${selectionText}` : '@';
      selection.insertText(textToInsert);
    });
  };

  return (
    <Flex mb='1' flex={flex}>
      <ToolbarButton
        icon={<MdTextFormat />}
        isActive={isActiveToggleFormat}
        ariaLabel='Text format'
        onClick={onToggleFormatClick}
      />
      <MediaAttachmentToolbarButton
        isEditable={true}
        getFileUploadUrls={getFileUploadUrls}
        onFilesAdded={onFilesAdded}
      />
      {!isMentionDisabled && (
        <ToolbarButton icon={<GoMention />} ariaLabel='Mention' onClick={onMentionClick} />
      )}
    </Flex>
  );
};

export default BottomToolbarPlugin;
