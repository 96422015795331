import { $generateNodesFromDOM } from '@lexical/html';
import { $createListNode, $isListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, $isDecoratorNode, $isElementNode } from 'lexical';
import { useEffect, useState } from 'react';

type InsertInitialHtmlPluginProps = {
  value: string | undefined | null;
  valueLexical?: string;
};

const InsertInitialHtmlPlugin = (props: InsertInitialHtmlPluginProps) => {
  const { value, valueLexical } = props;
  const [editor] = useLexicalComposerContext();
  const [isInitialContentParsed, setIsInitialContentParsed] = useState<boolean>(false);

  useEffect(() => {
    if (isInitialContentParsed) return;
    setIsInitialContentParsed(true);

    if (!value) return;
    editor.update(() => {
      if (valueLexical) {
        const editorState = editor.parseEditorState(valueLexical);
        editor.setEditorState(editorState);
        return;
      }

      const parser = new DOMParser();
      const dom = parser.parseFromString(value, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      // Select the root
      const root = $getRoot();
      root.clear();
      nodes.forEach((node) => {
        if ($isElementNode(node) || $isDecoratorNode(node)) {
          root.append(node);
        } else if ($isListNode(node)) {
          const listNode = $createListNode(node.getListType());
          listNode.append(node);
          root.append(listNode);
        }
      });
    });
  }, [editor, isInitialContentParsed, valueLexical, value]);

  return null;
};

export default InsertInitialHtmlPlugin;
