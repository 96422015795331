import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_CRITICAL, KEY_ENTER_COMMAND } from 'lexical';
import { useEffect } from 'react';

type KeyEventsHandlerPluginProps = {
  onCtrlEnterClick: () => void;
};

const IS_EVENT_PROPAGATION_DISABLED = false;

export default function KeyEventsHandlerPlugin(
  props: KeyEventsHandlerPluginProps
): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const { onCtrlEnterClick } = props;

  useEffect(() => {
    return editor.registerCommand<KeyboardEvent>(
      KEY_ENTER_COMMAND,
      ({ ctrlKey }: KeyboardEvent) => {
        if (ctrlKey) {
          onCtrlEnterClick();
        }
        return IS_EVENT_PROPAGATION_DISABLED;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, onCtrlEnterClick]);

  return null;
}
