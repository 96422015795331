import { Box, BoxProps } from '@chakra-ui/react';
import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import DisplayFileItem from '@/common/components/DisplayFileItem';
import { SLIDE_STYLE } from '@/common/components/RichMessageEditor';
import { IRichTextAttachment, IRichTextAttachmentInput } from '@/common/components/RichtextEditor';
import Swiper, { SCROLLBAR_MODULE } from '@/common/components/Swiper';
import { CustomSize } from '@/common/types';
import type { IComment } from '@/modules/workOrders/types/comment';
import { gql } from '@apollo/client';
import { SwiperSlide } from 'swiper/react';
import {
  DisplayCommentBody_ReplyCommentFragment,
  useDisplayCommentBodySuspenseQuery,
} from './DisplayCommentBody.generated';
import DisplayReplyComment from './DisplayReplyComment';

gql`
  fragment DisplayCommentBody_ReplyComment on BaseChatMessage {
    id
    message
    messageLexical
    user {
      id
      name
    }
    isEdited
    createdAt
    updatedAt
  }
`;
interface DisplayCommentBodyType extends BoxProps {
  comment: string;
  commentLexical?: string;
  attachments?: (IRichTextAttachment | IRichTextAttachmentInput)[];
  replyComment?: IComment | DisplayCommentBody_ReplyCommentFragment;
  onGoToCommentClick?: (commentId: number) => void;
}

const SLIDE_SIZE: CustomSize = {
  width: 150,
  height: 100,
};

gql`
  query DisplayCommentBody {
    users {
      id
      name
    }
  }
`;

const DisplayCommentBody = (props: DisplayCommentBodyType) => {
  const { comment, commentLexical, attachments, replyComment, color, onGoToCommentClick } = props;

  const {
    data: { users },
  } = useDisplayCommentBodySuspenseQuery();

  const [editor] = useLexicalComposerContext();

  let html = comment;

  if (commentLexical) {
    editor.update(() => {
      const jsonComment = JSON.parse(commentLexical);
      // もっと良い方法がありそうだけど、とりあえずこれで
      jsonComment['root']['children'].forEach(
        (node: { children: { userId?: string; userName: string }[] }) =>
          node.children.forEach((node) => {
            if (node.userId) {
              node.userName = users.find((user) => user.id === node.userId)?.name ?? '';
            }
          })
      );

      const editorState = editor.parseEditorState(JSON.stringify(jsonComment));

      html = editorState.read(() => $generateHtmlFromNodes(editor, null));
    });
  }

  const displayReplyComment = replyComment && replyComment.id && onGoToCommentClick;

  const replyCommentComment =
    replyComment &&
    (('comment' in replyComment && replyComment.comment) ||
      ('message' in replyComment && replyComment.message) ||
      '');

  const replyCommentLexical =
    replyComment &&
    (('commentLexical' in replyComment && replyComment.commentLexical) ||
      ('messageLexical' in replyComment && replyComment.messageLexical) ||
      '');

  return (
    <Box mx='5'>
      <Box color={color ?? 'gray.800'} dangerouslySetInnerHTML={{ __html: html }} />

      {attachments && (
        <Box mt={2} marginBottom={displayReplyComment ? 2 : 0}>
          <Swiper slideSize={SLIDE_SIZE} modules={[SCROLLBAR_MODULE]}>
            {attachments.map((attachment, index) => (
              <SwiperSlide key={index} style={SLIDE_STYLE}>
                <DisplayFileItem
                  key={attachment.fileId}
                  fileId={attachment.fileId}
                  contentType={attachment.contentType}
                  name={attachment.name}
                  path={attachment.path ?? ''}
                  size={SLIDE_SIZE}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      )}

      {displayReplyComment && (
        <DisplayReplyComment
          id={replyComment.id}
          user={replyComment.user}
          createdAt={replyComment.createdAt}
          isEdited={replyComment.isEdited}
          comment={replyCommentComment ?? ''}
          commentLexical={replyCommentLexical ?? ''}
          onGoToCommentClick={onGoToCommentClick}
        />
      )}
    </Box>
  );
};

export default DisplayCommentBody;
